<template>
  <div id="employees-view">
    <v-row>
      <v-col
        cols="12"
      >
        <employees-bio-panel
          :employees-data="employeesData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
        ></employees-bio-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline } from '@mdi/js'
import employeesStoreModule from '../employeesStoreModule'
import EmployeesBioPanel from './employees-bio-panel/EmployeesBioPanel.vue'

export default {
  components: {
    EmployeesBioPanel,
  },
  setup() {
    const EMPLOYEES_APP_STORE_MODULE_NAME = 'app-employees'

    // Register module
    if (!store.hasModule(EMPLOYEES_APP_STORE_MODULE_NAME)) {
      store.registerModule(EMPLOYEES_APP_STORE_MODULE_NAME, employeesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEES_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEES_APP_STORE_MODULE_NAME)
    })

    const employeesData = ref({})
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    store
      .dispatch('app-employees/fetchEmployees', { id: router.currentRoute.params.id })
      .then(response => {
        employeesData.value = response.data
        selectedPlan.value = response.data.currentPlan
      })
      .catch(error => {
        if (error?.response?.status === 404) {
          employeesData.value = {}
        }
      })

    const tabs = [{ icon: mdiAccountOutline, title: 'Overview' }]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      tabs,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      employeesData,
      employeesStoreModule,
      resolveCurrentPlanValue,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
