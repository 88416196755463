<template>
  <v-row class="employees-bio-panel">
    <!-- Employee profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="employeesData.avatar ? '' : 'primary'"
            :class="employeesData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img :src="require(`@/assets/images/avatars/8.png`)"></v-img>
          </v-avatar>
          <span class="mb-2">{{ !employee ? 'N/A' : employee.first_name + ' ' + employee.last_name }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">First Name:</span>
              <span class="text--secondary">{{ employee.first_name || 'N/A' }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Last Name:</span>
              <span class="text--secondary">{{ employee.last_name || 'N/A' }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Mobile Number:</span>
              <span class="text--secondary">{{ employee.mobile_number || 'N/A' }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Email:</span>
              <span class="text--secondary">{{ employee.email || 'N/A' }}</span>
            </v-list-item>

            <v-list v-for="(company, i) of employee.companies" dense :key="genUniqueKey(company)" class="px-0 mb-n2">
              <v-list-item dense class="px-0 mb-n2">
                <p class="font-weight-medium me-2">Company {{ ++i }}:</p>
                <p class="text--secondary">{{ company.name || 'N/A' }}</p>
              </v-list-item>
              <v-list-item dense class="px-0 mb-n2">
                <p class="font-weight-medium me-2">{{ company.name }} Break Time:</p>
                <p class="text--secondary">{{ company.break_time }}</p>
              </v-list-item>
              <v-list-item dense class="px-0 mb-n2">
                <p class="font-weight-medium me-2">{{ company.name }} Address:</p>
                <p class="text--secondary">
                  {{ company.building_number }}, {{ company.street_name }},
                  {{ (company.city && company.city.name_en) || 'N/A' }}
                </p>
              </v-list-item>
            </v-list>

            <v-list-item dense class="px-0 mb-n2">
              <p class="font-weight-medium me-2">Employee Number:</p>
              <p class="text--secondary">{{ employee.employee_number || 'N/A' }}</p>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <p class="font-weight-medium me-2">Points:</p>
              <p class="text--secondary">{{ employee.points || 0 }}</p>
            </v-list-item>

            <v-card-actions class="justify-center mt-4">
              <v-btn color="primary" class="me-3" @click="isBioDialogOpen = !isBioDialogOpen"> Edit </v-btn>
              <v-btn color="error" outlined @click="removeEmployee(employee.id, employee.company_id)"> Delete </v-btn>
            </v-card-actions>
          </v-list>
        </v-card-text>
      </v-card>

      <!-- edit profile dialog data -->
      <employees-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :employee-data="employee"
        @refresh="updateValue($event)"
      ></employees-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import { avatarText, kFormatter } from '@core/utils/filter'

import router from '@/router'
import store from '@/store'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle, mdiStarOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import useEmployeesList from '../../employees-list/useEmployeesList'
import EmployeesBioEdit from './EmployeesBioEdit.vue'

export default {
  components: {
    EmployeesBioEdit,
  },
  props: {
    employeesData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      employee: {},
    }
  },
  mounted() {
    store
      .dispatch('app-employees/fetchEmployee', router.currentRoute.params.id)
      .then(r => {
        this.employee = r.data.data
      })
      .catch(e => {
        if (e?.response?.status === 404) {
          this.employee = {}
        }
      })
  },
  methods: {
    removeEmployee(id, company_id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })
        .then(result => {
          if (result.isConfirmed) {
            store.dispatch('app-employees/removeEmployee', id).then(() => {
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
              this.$router.push({ path: `/apps/company/view/${company_id}` })
            })
          }
        })
        .catch(e => {
          if (e?.response?.status === 404) {
            this.employee = {}
          }
        })
    },
    updateValue(newValue) {
      this.employee.employee_number = newValue.employee_number
    },
  },
  setup() {
    const UNIQUE_KEY_PROP = '__unique_key_prop__'
    const KEY_PREFIX = '__key_prefix__' + Date.now() + '_'
    let uid = 0
    const { resolveEmployeesStatusVariant, resolveEmployeesRoleVariant } = useEmployeesList()

    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }
    const genUniqueKey = obj => {
      if (UNIQUE_KEY_PROP in obj) {
        return obj[UNIQUE_KEY_PROP]
      }
      const value = KEY_PREFIX + uid++
      Object.defineProperty(obj, UNIQUE_KEY_PROP, { value })
      return value
    }
    return {
      genUniqueKey,
      resolveEmployeesStatusVariant,
      resolveEmployeesRoleVariant,
      useEmployeesList,

      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiStarOutline,
      },
    }
  },
}
</script>
