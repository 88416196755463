<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-bio-dialog-open',false)"
  >
    <v-card class="employees-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Edit Employee Information
      </v-card-title>

      <v-card-text class="mt-5">
        <v-form
          v-model="valid"
          class="multi-col-validation"
        >
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="employeeDataLocal.employee_number"
                outlined
                dense
                label="Employee Number"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                submit
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-bio-dialog-open',false)"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    employeeData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isBillingAddress = ref(true)

    const employeeDataLocal = ref({})
    employeeDataLocal.value = JSON.parse(JSON.stringify(props.employeeData))

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]
    const languageOptions = ['English', 'Spanish', 'Portuguese', 'Russian', 'French', 'German']

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    // on form submit
    const onSubmit = async () => {
      if (valid.value) {
        try {
          const data = await store.dispatch('app-employees/updateEmployee', employeeDataLocal.value)
          emit('refresh', data.company_employee)
          emit('update:is-bio-dialog-open', false)
        } catch (error) {
          console.log(error)
        }
      } else {
        validate()
      }
      emit('update:is-bio-dialog-open', false)
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        employeeDataLocal.value = JSON.parse(JSON.stringify(props.employeeData))
      },
    )

    return {
      isBillingAddress,
      statusOptions,
      languageOptions,
      countries,
      employeeDataLocal,
      form,
      valid,
      validate,
      onSubmit,
    }
  },
}
</script>
